import React, { useContext, useRef } from 'react';

// context
import { DeviceContext } from 'contexts/DeviceContext';

import { PageAnimationWrapper } from 'components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';

// utils
import { getWebsitePlaceholderAsset } from 'components/manage/EditWebsite/common/HeroCustomizer/utils';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';

// types
import type { MultiPageHeroVariantProps } from 'components/publicWebsiteV2/common/MultiPageHeroContainer/types';

import { BaseHero } from 'components/publicWebsiteV2/common/MultiPageHeroContainer/MultiPageHeroContainer.styles';
import { useAnimationContext } from 'components/manage/EditWebsite/EditWebsiteAddEffects/components/AnimationContext/AnimationContext';
import { getAnimationPropsFullWidthHero } from 'components/publicWebsiteV2/common/OverlayAnimation/pageAnimationUtils';
import { HeroImageSized } from './MultiPageHeroSingleImage.styles';

const MultiPageHeroSingleImage: React.FC<MultiPageHeroVariantProps> = ({
  images,
  inPreview,
  overrideMobileHeight,
}) => {
  const { device } = useContext(DeviceContext);
  const isDesktop = inPreview === 'DESKTOP' || (!inPreview && device?.isDesktop());

  const bgImg =
    (images && images[0]?.image_url) ||
    getWebsitePlaceholderAsset('SINGLE_IMAGE_MULTI_PAGE', isDesktop, 1);

  const containerRef = useRef(null);
  const { width: containerWidth } = useContainerDimensions(containerRef);

  const { pageAnimation } = useAnimationContext();

  return (
    <BaseHero
      overrideMobileHeight={overrideMobileHeight}
      data-testid="MultiPageHeroSingleImage"
      ref={containerRef}
    >
      <PageAnimationWrapper
        animationProps={{
          isHero: true,
          direction: { wipeDirection: 'down', panDirection: 'right' },
          ...getAnimationPropsFullWidthHero(pageAnimation),
        }}
      >
        <HeroImageSized src={bgImg} alt="Hero Image" containerWidth={containerWidth} />
      </PageAnimationWrapper>
    </BaseHero>
  );
};

export default MultiPageHeroSingleImage;
